import React, { useState } from "react";

const FAQ = () => {
  const [faqLCPOpen, setFAQLCPOpen] = useState(false);
  const [faqFIDOpen, setFAQFIDOpen] = useState(false);
  const [faqCLSOpen, setFAQCLSOpen] = useState(false);
  const [faqWebVitalsOpen, setFAQWebVitalsOpen] = useState(false);
  const [faqCoreWebVitalsOpen, setFAQCoreWebVitalsOpen] = useState(false);
  const [faqDataSourceOpen, setFAQDataSourceOpen] = useState(false);
  const [
    faqSeventyFifthPercentileOpen,
    setFAQSeventyFifthPercentileOpen,
  ] = useState(false);
  const [faqAnalyzeOpen, setFAQAnalyzeOpen] = useState(false);
  const [faqMonitoringDIYOpen, setFAQMonitoringDIYOpen] = useState(false);
  const [faqMonitorOpen, setFAQMonitorOpen] = useState(false);
  const [faqAboutOpen, setFAQAboutOpen] = useState(false);

  const toggleFAQLCPOpen = () => {
    setFAQLCPOpen(!faqLCPOpen);
  };

  const toggleFAQFIDOpen = () => {
    setFAQFIDOpen(!faqFIDOpen);
  };

  const toggleFAQCLSOpen = () => {
    setFAQCLSOpen(!faqCLSOpen);
  };

  const toggleFAQWebVitalsOpen = () => {
    setFAQWebVitalsOpen(!faqWebVitalsOpen);
  };

  const toggleFAQCoreWebVitalsOpen = () => {
    setFAQCoreWebVitalsOpen(!faqCoreWebVitalsOpen);
  };

  const toggleFAQDataSourceOpen = () => {
    setFAQDataSourceOpen(!faqDataSourceOpen);
  };

  const toggleFAQSeventyFifthPercentileOpen = () => {
    setFAQSeventyFifthPercentileOpen(!faqSeventyFifthPercentileOpen);
  };

  const toggleFAQAnalyzeOpen = () => {
    setFAQAnalyzeOpen(!faqAnalyzeOpen);
  };

  const toggleFAQMonitoringDIYOpen = () => {
    setFAQMonitoringDIYOpen(!faqMonitoringDIYOpen);
  };

  const toggleFAQMonitorOpen = () => {
    setFAQMonitorOpen(!faqMonitorOpen);
  };

  const toggleFAQAboutOpen = () => {
    setFAQAboutOpen(!faqAboutOpen);
  };

  return (
    <div className="bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div id="FAQ" className="lg:mx-auto">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase lg:mx-auto">
            FAQ
          </p>
          <h3
            id="FAQAbout"
            className=" mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:mx-auto"
            onClick={toggleFAQAboutOpen}
          >
            About{" "}
            <span className="text-indigo-600">{faqAboutOpen ? "-" : "+"}</span>
          </h3>
          {faqAboutOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500 ">
                Throughout my career as an engineer, I&apos;ve always put the
                user experience first.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                While at PayPal years back, I fell in love with improving the
                user experience through performant sites. I became a performance
                champion on my team and enjoyed every minute of it. I began to
                deeply analyze metrics and business outcomes and saw how closely
                tied they were.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Then I created Spectatin to help make the web faster. Through
                building tools and working with founders and marketers, I&apos;m
                able to further my mission to make the web a better experience
                for everyone.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Performance has always been a challenging and interesting
                problem. From understanding how the web works to performance
                diagnoses on front-end JavaScript frameworks, server
                (re)architecture, image optimization, and especially to design.
                I enjoy diving into the breadth of topics and sharing knowledge.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Most recently, Google announced Core Web Vitals and the stance
                they&apos;re taking. Get fast. Stay fast. It&apos;s critical to
                users and all site owners.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Through Core Web Vitals, Google defined specific metrics and
                thresholds (which evolve yearly) to represent the user
                experience. And the thresholds Google has chosen are TOUGH to
                beat. Specifically LCP on mobile. And especially if (when)
                Google makes the thresholds more challenging to pass or raises
                the percentile from 75th to 80th and beyond. (We measured at the
                95th percentile when I was at PayPal, and let me tell you,
                it&apos;s not easy to build a performant site on slow mobile
                devices with slow connections.)
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Google also stated that Core Web Vitals will be used as part of
                Google&apos;s SEO ranking algorithm starting sometime in 2021.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Passing Core Web Vitals is going to be a big problem for nearly
                every site and a lot of site owners care a lot about SEO.
                Approaches to passing Core Web Vitals can be extremely costly
                and can require tons of time. Entire architectural rewrites are
                going to be needed for many sites that want to pass, and those
                sites need to get started now. Some sites will be able to pass
                Core Web Vitals through optimizations. Or site owners can arm
                themselves with the right knowledge and right experts, and
                passing Core Web Vitals can be as simple as some low-hanging
                fruit or a small design change.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Some site owners will arm themselves with the right knowledge
                and right experts to pass Core Web Vitals and stay well under
                the pass threshold. Passing Core Web Vitals can be as simple as
                some low-hanging fruit or a small design change - although not
                always.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                I predict that, for at least the next couple years, passing Core
                Web Vitals will be an awesome SEO differentiator (and
                that&apos;s totally ignoring the massive bounce rate
                improvements a faster site brings). The performance conversation
                will go upstream to marketing and design. Then, in a few years,
                passing Core Web Vitals will become table stakes. Companies will
                have awesome performance cultures. Yet Core Web Vitals will be
                continually more challenging to pass as they evolve. Knowing how
                to get fast and stay fast will be more important than ever
                before.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                It&apos;s a big problem and I want to help.
              </p>
            </div>
          ) : null}
          <h3
            className=" mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:mx-auto"
            onClick={toggleFAQWebVitalsOpen}
          >
            Web Vitals{" "}
            <span className="text-indigo-600">
              {faqWebVitalsOpen ? "-" : "+"}
            </span>
          </h3>
          {faqWebVitalsOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500 ">
                Web Vitals is an initiative by Google to provide unified
                guidance for quality signals that are essential to delivering a
                great user experience on the web.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Google has provided a number of tools over the years to measure
                and report on performance. Some developers are experts at using
                these tools, while others have found the abundance of both tools
                and metrics challenging to keep up with.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Site owners should not have to be performance gurus in order to
                understand the quality of experience they are delivering to
                their users. The Web Vitals initiative aims to simplify the
                landscape and help sites focus on the metrics that matter most,
                the Core Web Vitals.
              </p>
            </div>
          ) : null}
          <h3
            className=" mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:mx-auto"
            onClick={toggleFAQCoreWebVitalsOpen}
          >
            Core Web Vitals{" "}
            <span className="text-indigo-600">
              {faqCoreWebVitalsOpen ? "-" : "+"}
            </span>
          </h3>
          {faqCoreWebVitalsOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500 ">
                Core Web Vitals are the subset of Web Vitals that apply to all
                web pages, should be measured by all site owners, and will be
                surfaced across all Google tools. Each of the Core Web Vitals
                represents a distinct facet of the user experience, is
                measurable in the field, and reflects the real-world experience
                of a critical user-centric outcome.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                The metrics that make up Core Web Vitals will evolve over time.
                The current set for 2020 focuses on three aspects of the user
                experience—loading, interactivity, and visual stability—and
                includes the following metrics (and their respective
                thresholds):
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Largest Contentful Paint (LCP): measures loading performance. To
                provide a good user experience, LCP should occur within 2.5
                seconds of when the page first starts loading.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                First Input Delay (FID): measures interactivity. To provide a
                good user experience, pages should have a FID of less than 100
                milliseconds.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Cumulative Layout Shift (CLS): measures visual stability. To
                provide a good user experience, pages should maintain a CLS of
                less than 0.1.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                For each of the above metrics, to ensure you&apos;re hitting the
                recommended target for most of your users, a good threshold to
                measure is the 75th percentile of page loads, segmented across
                mobile and desktop devices.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Tools that assess Core Web Vitals compliance should consider a
                page passing if it meets the recommended targets at the 75th
                percentile for all of the above three metrics.
              </p>
            </div>
          ) : null}
          <h3
            className=" mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:mx-auto"
            onClick={toggleFAQLCPOpen}
          >
            Largest Contentful Paint (LCP){" "}
            <span className="text-indigo-600">{faqLCPOpen ? "-" : "+"}</span>
          </h3>
          {faqLCPOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500 ">
                LCP measures the loading experience. 75% of users must have LCP
                occur within 2.5 seconds of when the page first starts loading.
                LCP is typically the largest media (image/video) above the fold.
                Passing LCP can be very technical and very challenging,
                especially on mobile.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Can you find any sites that pass LCP on mobile?
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                A common factor amongst sites that pass LCP on mobile: no above
                the fold images. Passing LCP is part design, part engineering.
              </p>
            </div>
          ) : null}
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQFIDOpen}
          >
            First Input Delay (FID){" "}
            <span className="text-indigo-600">{faqFIDOpen ? "-" : "+"}</span>
          </h3>
          {faqFIDOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                FID measures interactivity. 75% of users must have FID less than
                100 milliseconds. Heavy JavaScript execution is the primary
                cause of poor FID.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                A common factor amongst sites that pass FID: the client executes
                minimal JavaScript. The server does as much of the heavy lifting
                as possible.
              </p>
            </div>
          ) : null}
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQCLSOpen}
          >
            Cumulative Layout Shift (CLS){" "}
            <span className="text-indigo-600">{faqCLSOpen ? "-" : "+"}</span>
          </h3>
          {faqCLSOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                CLS measures visual stability. 75% of users must have a CLS of
                less than 0.1. Any visual layout shift that occurs 500
                milliseconds or more after user input is added up to equal CLS.
                Images and ads that do not reserve space for their width and
                height are the biggest contributors to CLS.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                A common factor amongst sites that pass CLS: images used on the
                site use an agreed-upon aspect ratio, such as 16:9. Developers
                can then set width and height to reserve space for images that
                will download and display.
              </p>
            </div>
          ) : null}
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQSeventyFifthPercentileOpen}
          >
            75th Percentile{" "}
            <span className="text-indigo-600">
              {faqSeventyFifthPercentileOpen ? "-" : "+"}
            </span>
          </h3>
          {faqSeventyFifthPercentileOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Core Web Vitals are measured at the 75th percentile to determine
                pass/fail.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                A percentile is a measure used in statistics indicating the
                value below which a given percentage of observations in a group
                of observations falls. For example, the 75th percentile is the
                value below which 75% of the observations may be found.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Here&apos;s another way to think about it. Imagine that you
                measured the Largest Contentful Paint (LCP) for all of the
                sessions on your site over the last 28 days. Then you ordered
                the LCP measurements from smallest (e.g. 500ms) to largest (e.g.
                25000ms). Then you select the measurement that is exactly 75% of
                the way down the list (e.g. 4500ms). That is the 75th percentile
                measurement.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                75% of users have a better experience than the 75th percentile.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                25% of users have a poorer experience than the 75th percentile.
              </p>
            </div>
          ) : null}
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQDataSourceOpen}
          >
            Data Source{" "}
            <span className="text-indigo-600">
              {faqDataSourceOpen ? "-" : "+"}
            </span>
          </h3>
          {faqDataSourceOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                The Chrome User Experience Report collects anonymized, real user
                measurement data for each Core Web Vital. This data enables site
                owners to quickly assess their performance without requiring
                them to manually instrument analytics on their pages, and powers
                tools like PageSpeed Insights, Search Console&apos;s Core Web
                Vitals report, and the Spectatin analysis tool.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                The data in the Chrome UX Report is a 28-day rolling average of
                aggregated metrics. This means that the data presented in the
                Chrome UX Report at any given time is actual data for the past
                28 days aggregated together.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Data is updated daily around 04:00 UTC. Data will not differ
                within the same day, repeated calls will yield the same results.
              </p>
            </div>
          ) : null}
          <h3
            id="FAQAnalyze"
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQAnalyzeOpen}
          >
            Analysis{" "}
            <span className="text-indigo-600">
              {faqAnalyzeOpen ? "-" : "+"}
            </span>
          </h3>
          {faqAnalyzeOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                I built the Spectatin analysis tool to help analyze the same
                Core Web Vitals data Google uses to improve/penalize site SEO.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                All metrics and thresholds follow Google&apos;s Core Web Vitals
                standard. Automated analysis advice is a combination of industry
                best practices, Google&apos;s excellent performance people and
                work, and my own opinions which were forged while improving
                performance at PayPal.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                I continually update the Core Web Vitals analysis tool as Core
                Web Vitals evolve (yearly) or best practices evolve.
              </p>
            </div>
          ) : null}
          <h3
            id="FAQMonitoringDIY"
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQMonitoringDIYOpen}
          >
            Monitoring (DIY){" "}
            <span className="text-indigo-600">
              {faqMonitoringDIYOpen ? "-" : "+"}
            </span>
          </h3>
          {faqMonitoringDIYOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Want free monitoring?
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Use the free analysis tool on a daily/weekly/monthly basis and
                chart the results. Seriously! There are no limits when using the
                free analysis tool. I hope it helps you make your site and the
                web at large a better experience for users!
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                You can then give the results to your developers to analyze and
                they can help you decide what to do to improve your Core Web
                Vitals.
              </p>
            </div>
          ) : null}
          <h3
            id="FAQMonitor"
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            onClick={toggleFAQMonitorOpen}
          >
            Monitoring (Done for you){" "}
            <span className="text-indigo-600">
              {faqMonitorOpen ? "-" : "+"}
            </span>
          </h3>
          {faqMonitorOpen ? (
            <div>
              <p className="my-4 text-xl leading-7 text-gray-500">
                For those looking for expert help, I&apos;ll monitor and analyze
                your Core Web Vitals for you. We will work together so that you
                can pass Core Web Vitals.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                (Did I mention that on top of being a performance geek, I love
                looking at data?)
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                I will watch over your monitored URLs, segmented by desktop and
                mobile traffic. You will receive monthly videos including your
                Core Web Vitals analysis, low hanging fruit, performance user
                stories to give to developers, performance wins to celebrate
                with your team, and more.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                Once you&apos;re passing Core Web Vitals, you need to stay on
                top of passing. Content and traffic changes can affect things,
                even if no code changes are being made.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                I&apos;ll also be there by your side, available as a consultant
                on whatever unique problems arise each month.
              </p>
              <p className="my-4 text-xl leading-7 text-gray-500">
                It&apos;s like having a performance expert in your pocket.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
