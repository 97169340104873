import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FAQ from "../components/faq";

const FAQPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[
          `spectatin faq`,
          `core web vitals monitoring`,
          `largest contentful paint`,
          `first input delay`,
          `cumulative layout shift`,
        ]}
        title="FAQ"
      />

      <div className="mx-auto max-w-4xl py-12"><FAQ /></div>
    </Layout>
  );
}

export default FAQPage;
